import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import clock from './eventslideimg/clock@3x.png'
import calendar from './eventslideimg/calendar-date@3x.png'
import pin from './eventslideimg/marker-pin-01@3x.png'
import card from './eventslideimg/paymentIcon.png'
// import { onAuthStateChanged } from 'firebase/auth';
// import { getAuth } from 'firebase/auth';
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { doc, setDoc, getFirestore, collection, query, where, arrayUnion, getDocs, updateDoc } from "firebase/firestore";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { db } from './firebase';
import GuestlistView from './GuestlistView'

const auth = getAuth();


function EventDetails() {
    const { eventId } = useParams();
    const [event, setEvent] = useState(null);
    const [member, setMember] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isSignedup, setIsSignedup] = useState(false);
    const [showAttendance, setShowAttendance] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(false);
    const [showGuestlist, setShowGuestlist] = useState(false);
    const [internalEvent, setInternalEvent] = useState(false);

    useEffect(() => {
        fetchEvent()
               
    }, []);

    const fetchEvent = async () => {
        // try {

        console.log("eventid", eventId, userId)
        const eventRef = db.collection('events').doc(eventId);
        const doc = await eventRef.get();
        if (doc.exists) {
            const eventData = { id: doc.id, ...doc.data() };
            setEvent(eventData);

            if (eventData["externalURL"] == "" || eventData["externalURL"] == null) {
                setInternalEvent(true)
            }
        }
    };



    const checkIfSignedin = async => {
        console.log(event.title, "the event")
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in
                console.log('User is logged in:', user, user.id);

                // Perform any necessary actions for a logged-in user
            } else {
                console.log("user is not logged in")
                alert("Please login to sign up to an event")
            }
        });
    }

    const signUserUp = async () => {
        const uid = user.uid;

        if (!uid) {
            return;
        }

        const eventRef = db.collection("events").doc(event.id);
        const memberAttendingRef = eventRef.collection("membersAttending").doc(uid);

        memberAttendingRef.set({
            timestamp: new Date()
        })
            .then(() => {
                console.log("Member successfully added to membersAttending collection.");

                db.collection("members").where("uid", "==", uid).get()
                    .then((querySnapshot) => {
                        if (querySnapshot.empty) {
                            console.log("Member document not found");
                            setIsLoading(false)
                            return;
                        }

                        const document = querySnapshot.docs[0];
                        console.log("member document", document.id);
                        const memberRef = db.collection("members").doc(document.id);

                        memberRef.get()
                            .then((doc) => {
                                const events = doc.data().events || [];
                                events.push(event.id);

                                memberRef.update({ events })
                                    .then(() => {
                                        console.log("Member document successfully updated with event id.");
                                        setIsSignedup(true);
                                        setIsLoading(false)
                                    })
                                    .catch((error) => {
                                        console.log("Error updating member document:", error);
                                        setIsSignedup(false);
                                        setIsLoading(false)
                                    });
                            })
                            .catch((error) => {
                                console.log("Error retrieving member document:", error);
                            });
                    })
                    .catch((error) => {
                        console.log("Error getting member document:", error);
                        setIsLoading(false)
                    });
            })
            .catch((error) => {
                console.log("Error updating membersAttending collection:", error);
                setIsSignedup(false);
                setIsLoading(false)
            });

    }

    const cancelSignup = async () => {
        const uid = user.uid

        if (!uid) {
            return;
        }

        const eventRef = db.collection("events").doc(event.id);
        const memberAttendingRef = eventRef.collection("membersAttending").doc(uid);

        memberAttendingRef.delete()
            .then(() => {
                console.log("Member successfully removed from membersAttending collection.");

                db.collection("members").where("uid", "==", uid).get()
                    .then((querySnapshot) => {
                        if (querySnapshot.empty) {
                            console.log("Member document not found");
                            setIsLoading(false)
                            return;
                        }

                        const document = querySnapshot.docs[0];
                        console.log("member document", document.id);
                        const memberRef = db.collection("members").doc(document.id);

                        memberRef.get()
                            .then((doc) => {
                                const events = doc.data().events || [];
                                const updatedEvents = events.filter((eventId) => eventId !== event.id);

                                memberRef.update({ events: updatedEvents })
                                    .then(() => {
                                        console.log("Member document successfully updated. Event removed from events array.");
                                        setIsSignedup(false);
                                        setIsLoading(false)
                                    })
                                    .catch((error) => {
                                        console.log("Error updating member document:", error);
                                        setIsSignedup(true);
                                        setIsLoading(false)
                                    });
                            })
                            .catch((error) => {
                                console.log("Error retrieving member document:", error);
                                setIsLoading(false)
                            });
                    })
                    .catch((error) => {
                        console.log("Error getting member document:", error);
                        setIsLoading(false)
                    });
            })
            .catch((error) => {
                console.log("Error removing member from membersAttending collection:", error);
                setIsLoading(false)
            });
    }


    const handleClosePopup = () => {
        setShowGuestlist(false);
    };

    const handleGuestlistClick = async () => {
        setShowGuestlist(true)
    }


    const handleSignUp = async () => {
        // if (event.externalURL) {
        //     window.open(event.externalURL, '_blank');
        // }

        //PREPAYMENTS ON WEB
        // if (event.prepayment) {
        //     // const stripe_price = event.price * 100
        //     const confirmed = window.confirm(`Are you sure you want to sign up? Your card will be charged ${event.price}`);

        //     console.log("member", member)

        //     if (confirmed) {

        //         const details = {
        //             "stripe_price": event.stripe_price,
        //             "customerid": member.customer,
        //             "paymentid": member.payment_method,
        //             "product_id": event.product_id,
        //             "page_url": `event/${event.id}`,
        //             "eventid": event.id,
        //             "user": user.uid
        //         }
        //         triggerHTTP(details)
        //     }
        // }

        // else {
            const confirmed = window.confirm("Are you sure you want to sign up?");

            checkIfSignedin(event)

            if (confirmed) {
                signUserUp(event)
            }
        // }

    };

    const cancelSignUp = () => {

        if (event.prepayment) {

            const confirmed = window.confirm("Are you sure you want to cancel? Please email memberships@curve.club for your refund");

            if (confirmed) {
                console.log('Cancel sign up button clicked!');
                cancelSignup()
            }

        }
        else {

            const confirmed = window.confirm("Are you sure you want to cancel?");

            if (confirmed) {
                console.log('Cancel sign up button clicked!');
                cancelSignup()
            }

        }


    };

    const triggerHTTP = async (detailsObject) => {

        console.log("triggered")
        const superagent = require('superagent');


        try {

            console.log("triggered 2", detailsObject)

            const response = await superagent
                .post('https://stripe.ionaryder1.repl.co/member_portal_checkout')
                // .post('https://stripe-curve.herokuapp.com/event_payment')
                .send(detailsObject)
                .set('Content-Type', 'application/json')

            console.log(response.status)

            if (response.status >= 200 && response.status < 400) {
                console.log("success calling trigger!");
                const body = await response.body
                console.log("response", body.url)
                window.location.href = body.url
                // signUserUp(event)
                // alert("You're signed up!")
                return ({ status: 200 });
            }
            else {
                console.log("error here")
                throw new Error("other response status returned: " + response.status);
            }

        }
        catch (err) {
            console.log("error here", err)
            // alert("Error:", err)
            alert("Unable to sign up, there was an issue charging your card please get in touch with memberships@curve.club")
            // throw new Error("error calling triggerHTTP: " + err);

        }
    }

    return (
        <div>
            {event ? (
                <div className='event-page'>
                    <img src={event.url} alt="Event" className="event-detail-image" />
                    <h1><b>CURVE CLUB</b></h1>
                    <h1>{event.title}</h1>
                    <div className="details-container-all">
                        <div className="details-container">
                            <img src={calendar} alt="clock" className='event-icons' />
                            {event.date && event.startDatetime.seconds && (
                                <p>{new Date(event.startDatetime.seconds * 1000).toLocaleString("en-US", { dateStyle: "long" })}</p>
                            )}
                        </div>
                        <div className="details-container">
                            <img src={clock} alt="clock" className='event-icons' />
                            <p>
                                {new Date(event.startDatetime.seconds * 1000).toLocaleString("en-US", { timeStyle: "short" })}
                                -
                                {new Date(event.endDatetime.seconds * 1000).toLocaleString("en-US", { timeStyle: "short" })}
                            </p>
                        </div>
                        <div className="details-container">
                            <img src={pin} alt="pin" className='event-icons' />
                            <p>{event.location}</p>
                        </div>
                        <div className="details-container">
                            <img src={card} alt="card" className='event-icons' />
                            <p>{event.price}</p>
                        </div>
                    </div>
                    <h5>DESCRIPTION</h5>
                    <p className='event-description'>{event.description}</p>
                    <div className='event-buttons'>
  

                        {/* {!isSignedup && ( */}
                            <a href="https://members.curve.club/" style={{ textDecoration: 'none' }}>
                            <div className='register-button'>
                                REGISTER
                            </div>
                            </a>
                        {/* // )} */}
                        {/* {isSignedup && (
                            <div onClick={cancelSignUp} className='register-button'>
                                CANCEL
                            </div>
                        )}
                        {showGuestlist && <GuestlistView onClose={handleClosePopup} value={event} />} */}
                    </div>
                </div>
            ) : (
                <p>Loading event details...</p>
            )}
        </div>
    );
}

export default EventDetails;